import { Enums } from '@/app/types/supabase'

export const visibilityMappingAdmin = [
    { key: 'public', name: 'Shared between merchants' },
    { key: 'merchant_public', name: 'Public' },
    { key: 'merchant_unlisted', name: 'Unlisted' },
    { key: 'merchant_invite', name: 'Initiate using API' },
];

interface itemsType {
    name: string
    key: string
  }
  
export function visibilityFind(code: string | null): string | undefined {
    return visibilityMappingAdmin.find((element) => element.key === code)?.name;
}

export function createVisibilityDropdown(userType: Enums<'type_profile'>, merchantSubscription: Enums<'type_subscription_interview'>|null): itemsType[] {
    let items: itemsType[] = [];

    if (userType==='admin') items.push({ key: 'public', name: 'Shared between merchants' })
    items.push({ key: 'merchant_public', name: 'Public' })
    items.push({ key: 'merchant_unlisted', name: 'Unlisted' })

    if ((merchantSubscription!=='Career page') && (merchantSubscription!=='Avatar Interviews')) items.push({ key: 'merchant_invite', name: 'Initiate using API' })
    return items;
}