import {
    Tooltip,
    Divider,
  } from '@nextui-org/react'
import { InformationCircleIcon } from '@heroicons/react/24/outline'

export type PopoverType =  'interview-description'|'interview-candidate-expectations'|'interview-visibility'|'interview-status'|'knowledge-base-store'|'recording';

export default function InfoHelpPopover(
    { type }: { type:PopoverType }
) {

    return (
        <Tooltip
        size='sm'
        className='bg-black text-white'
        content={
          <div className="px-1 py-2 w-64 flex flex-col gap-2">
            {type==='recording' && <>
                <div className="text-base">Answer recording</div>
                <Divider className='bg-white'/>
                <div className="text-xs font-light">Our platform consistently captures transcriptions of candidates' answers. You can also enable audio recording alongside the transcript. This feature is particularly useful if you want to hear the candidate's accent.</div>
            </>}
            {type==='knowledge-base-store' && <>
                <div className="text-base">Additional context to Interactive avatar</div>
                <Divider className='bg-white'/>
                <div className="text-xs font-light">All documents stored in the selected Knowledge Base directory will be accessed by our interactive avatar. The avatar will utilize this information to provide accurate and relevant answers to candidates.</div>
            </>}
            {type==='interview-description' && <>
                <div className="text-base">This description will show up for your job applicants and should briefly describe the job opening.</div>
                <Divider className='bg-white'/>
                <div className="text-xs font-light"><b>Example:</b> We're looking for a Project Manager to Manage the implementation of project plans to meet planned objectives in our company</div>
            </>}
            {type==='interview-candidate-expectations' && <>
                <div className="text-base">Describe what you want in a candidate. Our system will give extra points if they meet these expectations during the interview.</div>
                <Divider className='bg-white'/>
                <div className="text-xs font-light"><b>Example:</b> We need a friendly person with a strong focus on achieving goals to guide a team of 6 developers</div>
            </>}
            {type==='interview-visibility' && <>
                <div className="text-base">Visibility governs how interviews are presented to candidates.</div>
                <Divider className='bg-white'/>
                <div className="text-xs font-light">
                    <ul className="list-disc ml-5 flex flex-col gap-1">
                        <li><span className="font-bold">Public:</span> The interview is prominently displayed on the homepage of our Interview Portal and is easily accessible through our search feature.</li>
                        <li><span className="font-bold">Unlished:</span> Interviews are not listed on the homepage, but anyone with a link can access them. This feature is useful for 2nd and 3rd interviews, allowing only selected candidates to attend.</li>
                        <li><span className="font-bold">Initiate using API:</span> This type of interview can be initiated exclusively through our API services.</li>
                    </ul>
                </div>
            </>}
            {type==='interview-status' && <>
                <div className="text-base">Status of the interview.</div>
                <Divider className='bg-white'/>
                <div className="text-xs font-light">
                    <ul className="list-disc ml-5 flex flex-col gap-1">
                        <li><span className="font-bold">Draft:</span> Interviews can be edited but are not accessible to candidates.</li>
                        <li><span className="font-bold">Active:</span> Candidates can take this interview. The interview's accessibility is governed by its "Visibility."</li>
                        <li><span className="font-bold">Completed:</span> Interviews are no longer accessible to candidates, allowing recruiters to make decisions about which candidates will advance to the next round.</li>
                        <li><span className="font-bold">Archived:</span> The interview is archived and can no longer be edited.</li>
                    </ul>
                </div>
            </>}
          </div>
        }
      >
        <InformationCircleIcon className='w-5 h-5 cursor-pointer text-secondary' />
      </Tooltip>
    )
}