import { DateTime } from 'luxon'

export function smartDate(setDate: string | null) {
    if (!setDate) return '';

    const date1 = DateTime.fromISO(setDate);

    const diffTime = DateTime.now().diff(date1, ["seconds"]).as('seconds');

    if (diffTime>0) {
      if (diffTime<60) return Math.round(diffTime)+' seconds ago';
      else if (diffTime<(60*60)) return Math.max(Math.round(diffTime/(60)),1)+' minutes ago';
      else if (diffTime<(60*60*24)) return Math.max(Math.round(diffTime/(60*60)),1)+' hours ago';
      else if (diffTime<(60*60*24*7)) return Math.max(Math.round(diffTime/(60*60*24)),1)+' days ago';
      else if (diffTime<(60*60*24*7*4)) return Math.max(Math.round(diffTime/(60*60*24*7)),1)+' weeks ago';
      else return (DateTime.fromISO(setDate.toString())
        .setLocale('en')
        .toFormat('ff'));
    }

    if (diffTime<(-60*60*24*7*4*8)) return (DateTime.fromISO(setDate.toString())
        .setLocale('en')
        .toFormat('ff'));
      else if (diffTime<(-60*60*24*7)) return Math.max(Math.round(Math.abs(diffTime/(60*60*24*7))),1)+' weeks from now';
      else if (diffTime<(-60*60*24)) return Math.max(Math.round(Math.abs(diffTime/(60*60*24))),1)+' days from now';
      else if (diffTime<(-60*60)) return Math.max(Math.round(Math.abs(diffTime/(60*60))),1)+' hours from now';
      else if (diffTime>(-60)) return Math.max(Math.round(Math.abs(diffTime/(60))),1)+' minutes from now';
      else return Math.round(Math.abs(diffTime))+' seconds from now';  
  }

export function formatedDate(setDate: string | null) {
  if ((!setDate) || (setDate===null) || (setDate==='')) return '';
  
  return (DateTime.fromISO(setDate.toString())
        .setLocale('en')
        .toFormat('ff'));
}

export function formatedDurationMiliSeconds(s: number | null | undefined) {
  if ((!s) || (s === null)) return '';

  // Pad to 2 or 3 digits, default is 2
  function pad(n:number, z:number=2) {
    z = z || 2;
    return ('00' + n).slice(-z);
  }

  const ms = s % 1000;
  s = (s - ms) / 1000;
  const secs = s % 60;
  s = (s - secs) / 60;
  const mins = s % 60;
  const hrs = (s - mins) / 60;

  // return with miliseconds
  //return pad(hrs) + ':' + pad(mins) + ':' + pad(secs) + '.' + pad(ms, 3);
  
  return pad(hrs) + ':' + pad(mins) + ':' + pad(secs);
}

export function formatedDuration(duration: string | null | undefined): string {
  if ((!duration) || (duration === null) || (duration==='')) return '';

  try {
    const split = duration.split('.');
    if (split.length===2) {
      const split1 = split[0].split(':');
      if (split1.length===2) {
        return split1[0]+' min '+split1[1]+' sec';
      }
    }
  } catch (e) {}

  return duration;
}