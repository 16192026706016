import { Json } from '../../types/supabase'

export type MerchantFeatures = {
    consumer_portal: boolean
    interview_portal: boolean
    consumer_sponsorship: boolean
    affiliate: boolean
  }
  
export function getMerchantFeatures(config_features?:Json | undefined | null):MerchantFeatures {
    if ((config_features !== undefined) && (config_features!==null)) {
        return (config_features as MerchantFeatures)
    } else {
        return {
            consumer_portal: true,
            interview_portal: false,
            consumer_sponsorship: false,
            affiliate: false,
        };
    }
}